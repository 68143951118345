<template>
  <div>
    <section class="py-5 gifting">
      <a href="/" class="btn btn-danger rounded-pill btn-close-gift px-5"
        >Close</a
      >
      <div class="container">
        <div class="row my-5">
          <div class="col-lg-12">
            <h5 class="text-center">
              <!-- Choose <span class="gift-text font-2">Gift!</span> -->

              Choose SKU
            </h5>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-8 col-lg-offset-2">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <SkuItem
                  v-for="(sku, i) in skus"
                  :key="i"
                  :skus="sku"
                  :background="getClassColor(i)"
                ></SkuItem>
                <!-- <SkuItem :skus="skus[1]" background="bg-f15"></SkuItem> -->
              </div>
              <!-- <div class="col-lg-6">
                <SkuItem :skus="skus[2]" background="bg-f30"></SkuItem>
              </div> -->
            </div>
          </div>
        </div>

        <!-- <div class="row mb-5">
          <div class="col-lg-12 text-center my-5">
            <button class="btn btn-dblue px-5" @click="proceedToCheckout()">
              Next
            </button>
          </div>
        </div> -->
      </div>
    </section>
    <button @click="topFunction" id="myBtn" title="Go to top">Top</button>
  </div>
</template>

<script>
import SkuItem from "../components/SkuItem.vue";
export default {
  name: "Gifting",
  components: {
    SkuItem,
  },
  data() {
    return {
      skus: [],
    };
  },
  methods: {
    async getSKUs() {
      await this.$load
        .get("/fiber-sku?group=true")
        .then((response) => {
          const res = response.data;
          if (res.status) {
           
             this.skus = res.data.fiberSku;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    proceedToCheckout() {
      // const a = this.$store.getters.GET_SKU;
      // console.log(a);

      this.$router.push({ path: "/checkout" });
    },

    getClassColor(i) {
      if (i === 0) {
        return "bg-f7";
      }

      if (i === 1) {
        return "bg-f15";
      }

      return "bg-f30";
    },
  },

  created() {
    // this.$store.dispatch("ADD_USER", {});
    this.getSKUs();
  },
};
</script>
